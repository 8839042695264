import {
  Dispatch,
  FC,
  ForwardRefExoticComponent,
  RefAttributes,
  SetStateAction
} from "react";
import {
  NonNullableProperties,
  RoleUser,
  TGenderBE,
  THostExperienceWithHosting,
  THostReasonToBecomeHost,
  THostSourceToHospi,
  THouseAccommodation,
  THouseAmountOfPeople,
  THouseCharacteristics,
  THouseCommonActivityOccurrence,
  THouseOtherItemsAndServices,
  THousePets,
  THousePrivateItems,
  THouseSecurityItems,
  THouseSharedItems,
  THouseTenantCharacteristics,
  THouseTenantDescription,
  THouseType,
  TOtherSearch,
  THousingType,
  TStudentAmountLookingPeople,
  TStudentCategorization,
  TStudentIdealMatch,
  TStudentMatchCharacteristics,
  TStudentPastExperience,
  TStudentPurpose,
  TStudentPurposeToLiveWithHost,
  TStudentReasonToBecomeTenant,
  TStudentSourceToHospi,
  TContractStatus,
  IBaseMatchData,
  TPaymentStatus
} from "../../interfaces/interfaces";
import { MatchStatusEnum } from "../../utils/constants";

export type TWizardType = "house" | RoleUser;

export interface IWizardStepMetaData {
  started: boolean;
  finished: boolean;
  created_at: string | null;
  updated_at: string | null;
}

export interface IStepBase {
  metadata: IWizardStepMetaData;
}

export type TStepPayload<Step> = Omit<Step, "metadata">;

export const WIZARD_STUDENT_STEPS = {
  Welcome: "Welcome",
  PersonalInfo: "PersonalInfo",
  Expectations: "Expectations",
  AboutMyself: "AboutMyself",
  Avatar: "Avatar",
  MatchExpectations: "MatchExpectations",
  Referral: "Referral",
  SearchPreferences: "SearchPreferences"
  // Overview: "Overview"
} as const;

export type T_WIZARD_STUDENT_STEP = keyof typeof WIZARD_STUDENT_STEPS;

export const WIZARD_STUDENT_STEPS_PATHS = {
  [WIZARD_STUDENT_STEPS.PersonalInfo]: "personal_info",
  [WIZARD_STUDENT_STEPS.Expectations]: "expectations",
  [WIZARD_STUDENT_STEPS.AboutMyself]: "about_myself",
  [WIZARD_STUDENT_STEPS.Avatar]: "avatar",
  [WIZARD_STUDENT_STEPS.MatchExpectations]: "match_expectations",
  [WIZARD_STUDENT_STEPS.Referral]: "referral",
  [WIZARD_STUDENT_STEPS.SearchPreferences]: "search_preferences"
} as const;

export type T_WIZARD_STUDENT_STEPS_PATH =
  (typeof WIZARD_STUDENT_STEPS_PATHS)[keyof typeof WIZARD_STUDENT_STEPS_PATHS];

export const WIZARD_HOST_STEPS = {
  Welcome: "Welcome",
  PersonalInfo: "PersonalInfo",
  Avatar: "Avatar",
  Referral: "Referral"
} as const;
export type T_WIZARD_HOST_STEP = keyof typeof WIZARD_HOST_STEPS;

export const WIZARD_HOST_STEPS_PATHS = {
  [WIZARD_HOST_STEPS.PersonalInfo]: "personal_info",
  [WIZARD_HOST_STEPS.Avatar]: "avatar",
  [WIZARD_HOST_STEPS.Referral]: "referral"
} as const;

export type T_WIZARD_HOST_STEPS_PATH =
  (typeof WIZARD_HOST_STEPS_PATHS)[keyof typeof WIZARD_HOST_STEPS_PATHS];

export interface IWizardMetaData<TStepPath> {
  started: boolean;
  finished: boolean;
  most_recent_active_step: TStepPath | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IStepValuePersonalInfo extends IStepBase {
  user_type?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  gender?: string | null;
  phone_code?: string | null;
  phone_number?: string | null;
  birth_country?: string | null;
  birth_date?: string | null;
  default_language?: string | null;
}

export interface IStepValueExpectations extends IStepBase {
  propose?: TStudentPurpose[] | null;
  past_experience?: TStudentPastExperience[] | null;
  study?: string | null;
}

export interface IStepValueAboutMyself extends IStepBase {
  spoken_languages?: string[] | null;
  categorization?: TStudentCategorization[] | null;
  description?: string | null;
}

export interface IStepValueAvatar extends IStepBase {
  avatar_thumbnail_url?: string | null;
}

export interface IStepValueMatchExpectations extends IStepBase {
  propose_to_live_with_host?: TStudentPurposeToLiveWithHost[] | null;
  ideal_match?: TStudentIdealMatch[] | null;
  match_characteristics?: TStudentMatchCharacteristics[] | null;
}

export interface IStepValueReferralStudent extends IStepBase {
  reason_to_become_tenant?: TStudentReasonToBecomeTenant[] | null;
  source_to_hospi?: TStudentSourceToHospi[] | null;
  other_search_options?: TOtherSearch[] | null;
}

export interface IStepValueSearchPreferences extends IStepBase {
  preferred_move_in?: string | null;
  preferred_move_out?: string | null;
  max_budget?: number | null;
  min_area?: number | null;
  search_location_string?: string | null;
  amount_looking_people?: TStudentAmountLookingPeople | null;
  latitude?: number; // wizard hasn't this value and doesn't save it
  longitude?: number; // wizard hasn't this value and doesn't save it
}

// host
export interface IStepValuePlanIntake extends IStepBase {
  from_dt?: string | null;
  to_dt?: string | null;
  done?: boolean | null;
  event_id?: string | null;
}

export interface IStepValueReferral extends IStepBase {
  reason_to_become_host?: THostReasonToBecomeHost[] | null;
  source_to_hospi?: THostSourceToHospi[] | null;
  experience_with_hosting?: THostExperienceWithHosting | null;
}

export interface IWizardBaseState<TStepPath> {
  metadata: IWizardMetaData<TStepPath>;
}

export interface IWizardStudentState
  extends IWizardBaseState<T_WIZARD_STUDENT_STEPS_PATH> {
  [WIZARD_STUDENT_STEPS_PATHS.PersonalInfo]: IStepValuePersonalInfo | null;
  [WIZARD_STUDENT_STEPS_PATHS.Expectations]: IStepValueExpectations | null;
  [WIZARD_STUDENT_STEPS_PATHS.AboutMyself]: IStepValueAboutMyself | null;
  [WIZARD_STUDENT_STEPS_PATHS.Avatar]: IStepValueAvatar | null;
  [WIZARD_STUDENT_STEPS_PATHS.MatchExpectations]: IStepValueMatchExpectations | null;
  [WIZARD_STUDENT_STEPS_PATHS.Referral]: IStepValueReferralStudent | null;
  [WIZARD_STUDENT_STEPS_PATHS.SearchPreferences]: IStepValueSearchPreferences | null;
}

export interface IWizardHostState
  extends IWizardBaseState<T_WIZARD_HOST_STEPS_PATH> {
  [WIZARD_HOST_STEPS_PATHS.PersonalInfo]: IStepValuePersonalInfo | null;
  [WIZARD_HOST_STEPS_PATHS.Avatar]: IStepValueAvatar | null;
  [WIZARD_HOST_STEPS_PATHS.Referral]: IStepValueReferral | null;
}

export type TStepConfiguration<TState, TStep extends string> = {
  component:
    | TWizardStepComponentStatic<TState>
    | TWizardStepComponentView<TState>
    | TWizardStepComponentEditable<TState>
    | TWizardStepComponentMultiEditable<TState, TStep>;
  container?: FC;
  type: WizardStepComponentType;
  nextButtonText?: string;
  showInOverview?: boolean;
};

export type TStepErrors<T> = null | Partial<{
  [K in keyof T]: string;
}>;

export enum WizardStepComponentType {
  Static = "Static",
  View = "View",
  Editable = "Editable",
  MultiEditable = "MultiEditable"
}

export type TWizardStepComponentRef<TState> = {
  save: () => Promise<TState | null>;
  savePartially?: () => Promise<TState>;
  getPossibleUpdatedState?: () => TState;
};

export interface TWizardStepComponentStatic<TState>
  extends ForwardRefExoticComponent<
    TWizardStepStaticProps & RefAttributes<TWizardStepComponentRef<TState>>
  > {}

export interface TWizardStepComponentView<TState>
  extends ForwardRefExoticComponent<
    TWizardStepViewProps<TState> &
      RefAttributes<TWizardStepComponentRef<TState>>
  > {}

export interface TWizardStepComponentEditable<TState>
  extends ForwardRefExoticComponent<
    TWizardStepEditableProps<TState> &
      RefAttributes<TWizardStepComponentRef<TState>>
  > {}

export type TWizardStepComponentEditableView<T, E = {}> = FC<
  {
    values: TStepPayload<T>;
    setValues: (values: TStepPayload<T>) => void;
    errors: TStepErrors<T>;
    readonly?: boolean;
    viewonly?: boolean;
    enabledFields?: {
      [key in keyof T]?: boolean;
    };
    setIsPlanedIntakeInsuranceModal?: (
      isPlanedIntakeInsuranceModal: boolean
    ) => void;
    rentDates?: { check_in_date: string; check_out_date: string }[];
    notWizardView?: boolean;
  } & E
>;

export interface TWizardStepComponentMultiEditable<TState, TStep extends string>
  extends ForwardRefExoticComponent<
    TWizardStepMultiEditableProps<TState, TStep> &
      RefAttributes<TWizardStepComponentRef<TState>>
  > {}

export type TWizardStepStaticProps = {};
export type TWizardStepViewProps<TState> = {
  state: TState;
};
export type TWizardStepEditableProps<TState> = {
  state: TState;
  readonly?: boolean;
  viewonly?: boolean;
};

export enum StepMultiEditableModes {
  View = "View",
  Edit = "Edit"
}

export type TWizardStepMultiEditableProps<TState, TStep extends string> = {
  state: TState;
  onEditModeChange: (mode: StepMultiEditableModes) => void;
  onStateChange: (state: TState) => void;
  stepsOrder: TStep[];
  getStepTitle?: (name: TStep) => string;
  stepsConfiguration: {
    [key in TStep]?: TStepConfiguration<TState, TStep>;
  };
};

// house
export const WIZARD_HOUSE_STEPS = {
  SpaceType: "SpaceType",
  HouseLocation: "HouseLocation",
  AboutHousehold: "AboutHousehold",
  AboutSpace: "AboutSpace",
  Facilities: "Facilities",
  Uniqueness: "Uniqueness",
  Photos: "Photos",
  PlanedIntake: "PlanedIntake",
  Availability: "Availability",
  Prices: "Prices",
  IdealTenant: "IdealTenant",
  ActivityExpectations: "ActivityExpectations",
  FinishConfig: "FinishConfig",
  FullOverview: "FullOverview",
  FullFinishConfig: "FullFinishConfig"
} as const;
export type T_WIZARD_HOUSE_STEP = keyof typeof WIZARD_HOUSE_STEPS;

export const WIZARD_HOUSE_STEPS_PATHS = {
  [WIZARD_HOUSE_STEPS.SpaceType]: "space_type",
  [WIZARD_HOUSE_STEPS.HouseLocation]: "house_location",
  [WIZARD_HOUSE_STEPS.AboutHousehold]: "about_household",
  [WIZARD_HOUSE_STEPS.AboutSpace]: "about_space",
  [WIZARD_HOUSE_STEPS.Facilities]: "facilities",
  [WIZARD_HOUSE_STEPS.Uniqueness]: "uniqueness",
  [WIZARD_HOUSE_STEPS.Photos]: "photos",
  [WIZARD_HOUSE_STEPS.Availability]: "availability",
  [WIZARD_HOUSE_STEPS.Prices]: "prices",
  [WIZARD_HOUSE_STEPS.IdealTenant]: "ideal_tenant",
  [WIZARD_HOUSE_STEPS.ActivityExpectations]: "activity_expectations",
  [WIZARD_HOUSE_STEPS.FinishConfig]: "finish_config",
  [WIZARD_HOUSE_STEPS.FullFinishConfig]: "finish_config"
} as const;

export type T_WIZARD_HOUSE_STEPS_PATH =
  (typeof WIZARD_HOUSE_STEPS_PATHS)[keyof typeof WIZARD_HOUSE_STEPS_PATHS];

export interface IHouseStepSpaceType extends IStepBase {
  kind?: THouseType | null;
}

export interface IHouseStepHouseLocation extends IStepBase {
  postal_code?: string | null;
  house_number?: string | null;
  additional_house_number?: string | null;
  street?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface IHouseStepAboutHousehold extends IStepBase {
  housing_type?: THousingType | null;
  housing_corporation_name?: string | null;
  characteristics?: THouseCharacteristics | null;
  people_living?: string[] | null;
  pets?: THousePets[] | null;
  spoken_languages?: string[] | null;
  description?: string | null;
}

export interface IHouseStepAboutSpace extends IStepBase {
  accommodation?: THouseAccommodation | null;
  rent_area?: string | null;
  entire_house_area?: string | null;
  amount_of_people?: THouseAmountOfPeople | null;
  private_items?: THousePrivateItems[] | null;
  shared_items?: THouseSharedItems[] | null;
}

export interface IHouseStepFacilities extends IStepBase {
  security_items?: THouseSecurityItems[] | null;
  other_items_and_services?: THouseOtherItemsAndServices[] | null;
}

export interface IHouseStepUniqueness extends IStepBase {
  accommodation?: TStudentMatchCharacteristics[] | null;
  place_description?: string | null;
}

export type THousePhoto = {
  file_name: string;
  order: number;
  timestamp?: string;
  url?: string;
};

export interface IHouseStepPhotos extends IStepBase {
  photos?: THousePhoto[];
}

export interface IHouseStepAvailability extends IStepBase {
  available_from?: string | null;
  available_to?: string | null;
}

export interface IHouseStepPrices extends IStepBase {
  price?: string | null;
  additional_expenses?: string | null;
  service_costs?: string | null;
  deposit?: string | null;
}

export interface IHouseStepIdealTenant extends IStepBase {
  min_age?: string | null;
  max_age?: string | null;
  gender?: TGenderBE | null;
  tenant_spoken_languages?: string[] | null;
  tenant_characteristics?: THouseTenantCharacteristics[] | null;
  tenant_description?: THouseTenantDescription[] | null;
}

export interface IHouseStepActivityExpectations extends IStepBase {
  spend_time?: THouseCommonActivityOccurrence | null;
  help_tenant?: THouseCommonActivityOccurrence | null;
}

export interface IHouseStepFinishConfig extends IStepBase {
  host_verified?: boolean | null;
  publish_house_after_finish?: boolean | null;
}

export type TWizardHouseType = "description" | "publish" | "full";

export interface IWizardHouseState
  extends IWizardBaseState<T_WIZARD_HOUSE_STEPS_PATH> {
  house_id?: string | null;
  user_id?: string | null;
  wizard_type?: TWizardHouseType | null;
  space_type: IHouseStepSpaceType | null;
  house_location: IHouseStepHouseLocation | null;
  about_household: IHouseStepAboutHousehold | null;
  about_space: IHouseStepAboutSpace | null;
  facilities: IHouseStepFacilities | null;
  uniqueness: IHouseStepUniqueness | null;
  photos: IHouseStepPhotos | null;
  availability: IHouseStepAvailability | null;
  prices: IHouseStepPrices | null;
  ideal_tenant: IHouseStepIdealTenant | null;
  activity_expectations: IHouseStepActivityExpectations | null;
  finish_config: IHouseStepFinishConfig | null;
}

export type IWizardHouseStateShort = NonNullableProperties<
  Required<
    Pick<IWizardHouseState, "metadata" | "house_id" | "user_id" | "wizard_type">
  >
>;

export const EMPTY_STEP_METADATA = {
  started: false,
  finished: false,
  created_at: null,
  updated_at: null
};

// Contract
export const AllowedUsageValues = ["joint", "private"] as const;
export type TAllowedUsage = (typeof AllowedUsageValues)[number];

export const HouseSideValues = ["front", "rear"] as const;
type THouseSide = (typeof HouseSideValues)[number];

export const OwnershipValues = ["owner", "tenant"] as const;
type TOwnership = (typeof OwnershipValues)[number];

export type TContractConfig = {
  // lng: "gb" | "nl";
  status?: TContractStatus;
  house_id?: string;
  student_id?: string;
  host_id?: string;
  payment_status?: TPaymentStatus | null;
  payment_link?: string | null;
  match_status?: MatchStatusEnum;
  check_out_date?: string;
};

export interface IContractData {
  host_name: string;
  address: string;
  residence_place: string;
  tenant_name: string;
  start_data: string; // iso Date e.g "2024-05-22"
  floor: string | null; // "integer" | null
  house_side: THouseSide | null;
  rent_area: string; // Decimal
  kitchen: null | TAllowedUsage;
  bath: null | TAllowedUsage;
  toilet: null | TAllowedUsage;
  balcony: null | TAllowedUsage;
  living_room: boolean;
  garden: boolean;
  storage_space: boolean;
  custom_field_1: string | null;
  custom_field_2: string | null;
  ownership: TOwnership | null;
  rental_price: string; // Decimal
  service_cost: string; // Decimal
  additional_expenses: string; // Decimal
  bank_account: string | null;
  deposit: string; // Decimal
  host_sign_date?: string; // DateTime e.g "2024-05-22T16:08:20.773640", string type after host signature
  student_sign_date?: string; // DateTime, string type after student signature
  host_sign_url?: string; // string type after host signature
  student_sign_url?: string; // string type after student signature
  payment_date: number; // int. from 1 to 31
}

export interface IContractPayload extends IContractData {
  host_sign_img: string | null;
  student_sign_img: string | null;
}

export interface IContract extends IBaseMatchData {
  contract_id: string;
  contract_status: TContractStatus;
  primary_language: "en" | "nl"; // isn't used now on UI
  created_at: string; // DateTime e.g "2024-05-22T16:08:20.773640"
  updated_at: string; // DateTime
  contract_data: IContractData;
}

export type TWizardContract<T, E = {}> = FC<
  {
    values: T;
    setValues: (values: T) => void;
    // initialContractData?: IContractData | null;
    errors?: Partial<T>;
    setErrors?: Dispatch<SetStateAction<Partial<T>>>;
    setProgress?: Dispatch<SetStateAction<number>>;
  } & E
>;
