import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select } from "antd";

import {
  IStepValueReferral,
  IWizardHostState,
  TStepErrors,
  TStepPayload,
  TWizardStepComponentEditable,
  TWizardStepComponentEditableView,
  WIZARD_HOST_STEPS_PATHS
} from "../../../interfaces";
import { UserAPI } from "API/userApi";
import { getStepErrorsFromResponse, getChangedFields } from "../../../utils";
import {
  HostExperienceWithHostingValues,
  HostReasonToBecomeHostValues,
  HostSourceToHospiValues
} from "interfaces/interfaces";
import { StepViewOnlyValue } from "../../../Student/styles";
import { useContextHospi } from "../../../../../context/ContextHospi";
import { WIZARD_HOST_DEFAULT_STATE } from "../../constants";
import { SelectWithLongText } from "../../../../generic";

const statePath = WIZARD_HOST_STEPS_PATHS.Referral;
export const WizardStepReferral: TWizardStepComponentEditable<IWizardHostState> =
  forwardRef(({ state, readonly, viewonly }, ref) => {
    const [values, setValues] = useState<TStepPayload<IStepValueReferral>>(
      state[statePath]
        ? {
            ...state[statePath],
            reason_to_become_host:
              Array.isArray(state[statePath]?.reason_to_become_host) &&
              state[statePath].reason_to_become_host.length > 0
                ? state[statePath].reason_to_become_host
                : WIZARD_HOST_DEFAULT_STATE.referral?.reason_to_become_host,
            source_to_hospi:
              Array.isArray(state[statePath]?.source_to_hospi) &&
              state[statePath].source_to_hospi.length > 0
                ? state[statePath].source_to_hospi
                : WIZARD_HOST_DEFAULT_STATE.referral?.source_to_hospi
          }
        : {}
    );
    const [errors, setErrors] = useState<TStepErrors<IStepValueReferral>>(null);
    const { cognitoUser } = useContextHospi();
    useImperativeHandle(ref, () => ({
      save: () => {
        setErrors(null);
        window.dataLayer.push({
          event: "Host_signup_1.5_host_motivation",
          user_id: cognitoUser?.id,
          user_type: "host"
        });
        return UserAPI.wizardHostSaveReferral(values)
          .then((_) => {
            // return { ...state, [statePath]: result }; // use this instead of returning null in case this step becomes not last
            return null;
          })
          .catch((e) => {
            setErrors(getStepErrorsFromResponse(e));
            return Promise.reject(e);
          });
      },
      savePartially: () => {
        setErrors(null);
        return UserAPI.wizardHostSaveReferralPartially(
          getChangedFields(state[statePath], values)
        )
          .then((result) => {
            return {
              ...state,
              [statePath]: result
            };
          })
          .catch((e) => {
            setErrors(getStepErrorsFromResponse(e));
            return Promise.reject(e);
          });
      }
    }));

    return (
      <WizardStepReferralView
        values={values}
        setValues={setValues}
        errors={errors}
        readonly={readonly}
        viewonly={viewonly}
      />
    );
  });

export const WizardStepReferralView: TWizardStepComponentEditableView<
  IStepValueReferral
> = ({ errors, values, setValues, viewonly, readonly }) => {
  const { t } = useTranslation("host");
  const { t: houset } = useTranslation("house");

  const reasonToBecomeHostOptions = HostReasonToBecomeHostValues.map((v) => ({
    value: v,
    label: (
      t(`hostProfile.referral.form.reasonToBecomeHost.options`, {
        returnObjects: true
      }) as { [key: string]: string }
    )[v]
  }));

  const sourceToHospiOptions = HostSourceToHospiValues.map((v) => ({
    value: v,
    label: (
      t(`hostProfile.referral.form.sourceToHospi.options`, {
        returnObjects: true
      }) as { [key: string]: string }
    )[v]
  }));

  const experienceWithHostingOptions = HostExperienceWithHostingValues.map(
    (v) => ({
      value: v,
      label: (
        t(`hostProfile.referral.form.experienceWithHosting.options`, {
          returnObjects: true
        }) as { [key: string]: string }
      )[v]
    })
  );

  return (
    <Form layout="vertical">
      <Form.Item
        label={t(`hostProfile.referral.form.reasonToBecomeHost.title`)}
        className={
          viewonly &&
          (!values.reason_to_become_host ||
            (Array.isArray(values.reason_to_become_host) &&
              values.reason_to_become_host.length === 0))
            ? "noViewonlyValue"
            : "isValue"
        }
        validateStatus={
          errors?.reason_to_become_host &&
          (!values.reason_to_become_host ||
            (Array.isArray(values.reason_to_become_host) &&
              values.reason_to_become_host.length === 0))
            ? "error"
            : ""
        }
        help={
          errors?.reason_to_become_host &&
          (!values.reason_to_become_host ||
            (Array.isArray(values.reason_to_become_host) &&
              values.reason_to_become_host.length === 0))
            ? houset("wizard.errors.generic")
            : ""
        }
        htmlFor="reason_to_become_host"
      >
        {viewonly ? (
          Array.isArray(values.reason_to_become_host) &&
          values.reason_to_become_host.length > 0 ? (
            <StepViewOnlyValue>
              {reasonToBecomeHostOptions
                .filter((o) => values.reason_to_become_host?.includes(o.value))
                .map((o) => o.label)
                ?.join(", ")}
            </StepViewOnlyValue>
          ) : null
        ) : (
          <SelectWithLongText
            className="selectWithLongText"
            options={reasonToBecomeHostOptions}
            value={values.reason_to_become_host ?? undefined}
            size="large"
            mode="multiple"
            disabled={readonly}
            onChange={(value) => {
              setValues({ ...values, reason_to_become_host: value });
            }}
            id="reason_to_become_host"
          />
        )}
      </Form.Item>
      <Form.Item
        label={t(`hostProfile.referral.form.sourceToHospi.title`)}
        className={
          viewonly &&
          (!values.source_to_hospi ||
            (Array.isArray(values.source_to_hospi) &&
              values.source_to_hospi.length === 0))
            ? "noViewonlyValue"
            : "isValue"
        }
        validateStatus={
          errors?.source_to_hospi &&
          (!values.source_to_hospi ||
            (Array.isArray(values.source_to_hospi) &&
              values.source_to_hospi.length === 0))
            ? "error"
            : ""
        }
        help={
          errors?.source_to_hospi &&
          (!values.source_to_hospi ||
            (Array.isArray(values.source_to_hospi) &&
              values.source_to_hospi.length === 0))
            ? houset("wizard.errors.generic")
            : ""
        }
        htmlFor="source_to_hospi"
      >
        {viewonly ? (
          Array.isArray(values.source_to_hospi) &&
          values.source_to_hospi.length > 0 ? (
            <StepViewOnlyValue>
              {sourceToHospiOptions
                .filter((o) => values.source_to_hospi?.includes(o.value))
                .map((o) => o.label)
                ?.join(", ")}
            </StepViewOnlyValue>
          ) : null
        ) : (
          <SelectWithLongText
            className="selectWithLongText"
            options={sourceToHospiOptions}
            value={values.source_to_hospi ?? undefined}
            size="large"
            mode="multiple"
            disabled={readonly}
            onChange={(value) =>
              setValues({ ...values, source_to_hospi: value })
            }
            id="source_to_hospi"
          />
        )}
      </Form.Item>
      <Form.Item
        label={t(`hostProfile.referral.form.experienceWithHosting.title`)}
        className={
          viewonly &&
          (!values.experience_with_hosting ||
            (Array.isArray(values.experience_with_hosting) &&
              values.experience_with_hosting.length === 0))
            ? "noViewonlyValue"
            : "isValue"
        }
        validateStatus={
          errors?.experience_with_hosting && !values.experience_with_hosting
            ? "error"
            : ""
        }
        help={
          errors?.experience_with_hosting && !values.experience_with_hosting
            ? houset("wizard.errors.generic")
            : ""
        }
        htmlFor="experience_with_hosting"
      >
        {viewonly ? (
          Array.isArray(values.experience_with_hosting) &&
          values.experience_with_hosting.length > 0 ? (
            <StepViewOnlyValue>
              {experienceWithHostingOptions.find((o) =>
                values.experience_with_hosting?.includes(o.value)
              )?.label ?? ""}
            </StepViewOnlyValue>
          ) : null
        ) : (
          <Select
            options={experienceWithHostingOptions}
            value={values.experience_with_hosting ?? undefined}
            size="large"
            disabled={readonly}
            onChange={(value) =>
              setValues({ ...values, experience_with_hosting: value })
            }
            id="experience_with_hosting"
          />
        )}
      </Form.Item>
    </Form>
  );
};
