import {
  IStepValuePersonalInfo,
  TWizardStepComponentEditable,
  WIZARD_STUDENT_STEPS_PATHS,
  TStepPayload,
  TStepErrors,
  TWizardStepComponentEditableView,
  IWizardStudentState
} from "../../../interfaces";
import { DatePicker, Input, Form, Select } from "antd";
import {
  memo,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import dayjs from "dayjs";
import {
  formatAsUTCToServerDate,
  formatDateToShort,
  parseServerDate
} from "../../../../../utils/time";
import { useTranslation } from "react-i18next";
import { useContextHospi } from "../../../../../context/ContextHospi";
import { UserAPI } from "../../../../../API/userApi";
import { getChangedFields, getStepErrorsFromResponse } from "../../../utils";
import { getCountries, getPhoneCodes } from "../../../../../utils/fetchMapInfo";
import { TLang } from "../../../../../assets/data/isoNameLanguage";
import styled from "styled-components/macro";
import { GenderValues } from "../../../../../interfaces/interfaces";
import { RadioGroup } from "../../../components";
import { DATE_PICKER_DATE_FORMAT } from "../../../../../utils/constants";

import { StepViewOnlyValue } from "../../styles";

const statePath = WIZARD_STUDENT_STEPS_PATHS.PersonalInfo;

export const WizardStepPersonalInfo: TWizardStepComponentEditable<IWizardStudentState> =
  forwardRef(({ state, readonly, viewonly }, ref) => {
    const [values, setValues] = useState<TStepPayload<IStepValuePersonalInfo>>(
      state[statePath] ?? {}
    );
    const [errors, setErrors] =
      useState<TStepErrors<IStepValuePersonalInfo>>(null);

    const { currentUserRole } = useContextHospi();
    const { i18n } = useTranslation(currentUserRole);
    const { cognitoUser } = useContextHospi();
    useImperativeHandle(ref, () => ({
      save: () => {
        setErrors(null);
        return UserAPI.wizardStudentSavePersonalInfo({
          ...values,
          default_language: i18n.language,
          user_type: currentUserRole
        })
          .then((result) => {
            window.dataLayer.push({
              event: "student_wizard_5_personal_info",
              user_id: cognitoUser?.id,
              user_type: "student"
            });
            return {
              ...state,
              [statePath]: result
            };
          })
          .catch((e) => {
            setErrors(getStepErrorsFromResponse(e));
            return Promise.reject(e);
          });
      },
      savePartially: () => {
        setErrors(null);
        return UserAPI.wizardStudentSavePersonalInfoPartially(
          getChangedFields(state[statePath], values)
        )
          .then((result) => {
            return {
              ...state,
              [statePath]: result
            };
          })
          .catch((e) => {
            setErrors(getStepErrorsFromResponse(e));
            return Promise.reject(e);
          });
      }
    }));

    return (
      <WizardStepPersonalInfoView
        values={values}
        setValues={setValues}
        errors={errors}
        readonly={readonly}
        viewonly={viewonly}
      />
    );
  });

const GenderFormItem = styled(Form.Item)`
  grid-column-start: span 2;
`;

const Root = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    ${GenderFormItem} {
      grid-column-start: auto;
    }
  }
`;

const CountryCodeSelect = styled(Select)`
  .ant-select-selection-item {
    width: 100px;
  }
`;

export const WizardStepPersonalInfoView: TWizardStepComponentEditableView<IStepValuePersonalInfo> =
  memo(({ values, setValues, errors, readonly, viewonly }) => {
    const translationUserRole = "student";
    const { t, i18n } = useTranslation(translationUserRole);
    const phoneCodes = useMemo(
      () =>
        getPhoneCodes({
          showCountryNames: true,
          language: i18n.language.toUpperCase() as TLang
        }),
      [i18n.language]
    );
    const countries = useMemo(
      () => getCountries(i18n.language.toUpperCase() as TLang),
      [i18n.language]
    );

    const inputSize = "large";

    const genderOptions = GenderValues.map((o) => ({
      value: o,
      label: t(
        `${translationUserRole}Profile.aboutMeInfo.form.gender.options.${o}`
      )
    }));

    return (
      <Root layout="vertical">
        <Form.Item
          label={t(
            `${translationUserRole}Profile.personalDetails.form.firstName.title`
          )}
          className={
            viewonly && !values.first_name?.length
              ? "noViewonlyValue"
              : "isValue"
          }
          validateStatus={
            errors?.first_name && !values.first_name ? "error" : ""
          }
          help={
            errors?.first_name && !values.first_name
              ? t(
                  `${translationUserRole}Profile.personalDetails.form.generic.error`
                )
              : ""
          }
        >
          {viewonly ? (
            !!values.first_name?.length ? (
              <StepViewOnlyValue>{values.first_name}</StepViewOnlyValue>
            ) : null
          ) : (
            <Input
              size={inputSize}
              disabled={readonly}
              value={values.first_name ?? undefined}
              placeholder={t(
                `${translationUserRole}Profile.personalDetails.form.firstName.placeholder`
              )}
              onChange={(e) => {
                setValues({
                  ...values,
                  first_name: e.target.value
                });
              }}
            />
          )}
        </Form.Item>
        <Form.Item
          label={t(
            `${translationUserRole}Profile.personalDetails.form.lastName.title`
          )}
          className={
            viewonly && !values.last_name?.length
              ? "noViewonlyValue"
              : "isValue"
          }
          validateStatus={errors?.last_name && !values.last_name ? "error" : ""}
          help={
            errors?.last_name && !values.last_name
              ? t(
                  `${translationUserRole}Profile.personalDetails.form.generic.error`
                )
              : ""
          }
        >
          {viewonly ? (
            !!values.last_name?.length ? (
              <StepViewOnlyValue>{values.last_name}</StepViewOnlyValue>
            ) : null
          ) : (
            <Input
              size={inputSize}
              disabled={readonly}
              value={values.last_name ?? undefined}
              placeholder={t(
                `${translationUserRole}Profile.personalDetails.form.lastName.placeholder`
              )}
              onChange={(e) => {
                setValues({
                  ...values,
                  last_name: e.target.value
                });
              }}
            />
          )}
        </Form.Item>
        <GenderFormItem
          label={t(
            `${translationUserRole}Profile.aboutMeInfo.form.gender.title`
          )}
          className={
            viewonly && !values.gender?.length ? "noViewonlyValue" : "isValue"
          }
          validateStatus={errors?.gender && !values.gender ? "error" : ""}
          help={
            errors?.gender && !values.gender
              ? t(`${translationUserRole}Profile.aboutMeInfo.form.gender.error`)
              : ""
          }
        >
          {viewonly ? (
            !!values.gender?.length ? (
              <StepViewOnlyValue>
                {genderOptions.find((v) => v.value === values.gender)?.label ??
                  ""}
              </StepViewOnlyValue>
            ) : null
          ) : (
            <RadioGroup
              value={values.gender ?? undefined}
              options={genderOptions}
              disabled={readonly}
              onChange={(val) => {
                setValues({
                  ...values,
                  gender: val.target.value
                });
              }}
            />
          )}
        </GenderFormItem>
        <Form.Item
          label={t(
            `${translationUserRole}Profile.personalDetails.form.birthDate.title`
          )}
          className={
            viewonly && !values.birth_date?.length
              ? "noViewonlyValue"
              : "isValue"
          }
          validateStatus={
            errors?.birth_date && !values.birth_date ? "error" : ""
          }
          help={
            errors?.birth_date && !values.birth_date
              ? t(
                  `${translationUserRole}Profile.personalDetails.form.generic.error`
                )
              : ""
          }
        >
          {viewonly ? (
            !!values.birth_date?.length ? (
              <StepViewOnlyValue>
                {formatDateToShort(parseServerDate(values.birth_date))}
              </StepViewOnlyValue>
            ) : null
          ) : (
            <DatePicker
              size={inputSize}
              disabled={readonly}
              value={
                values.birth_date
                  ? dayjs(parseServerDate(values.birth_date))
                  : undefined
              }
              style={{ width: "100%" }}
              placeholder={t(
                `${translationUserRole}Profile.personalDetails.form.birthDate.placeholder`
              )}
              onChange={(e) => {
                setValues({
                  ...values,
                  birth_date: e ? formatAsUTCToServerDate(e.toDate()) : null
                });
              }}
              format={DATE_PICKER_DATE_FORMAT}
              disabledDate={(date) => {
                return (
                  date.toDate().getTime() >=
                  dayjs(new Date()).startOf("day").toDate().getTime()
                );
              }}
            />
          )}
        </Form.Item>
        <Form.Item
          label={t(
            `${translationUserRole}Profile.personalDetails.form.birthCountry.title`
          )}
          className={
            viewonly && !values.birth_country?.length
              ? "noViewonlyValue"
              : "isValue"
          }
          validateStatus={
            errors?.birth_country && !values.birth_country ? "error" : ""
          }
          help={
            errors?.birth_country && !values.birth_country
              ? t(
                  `${translationUserRole}Profile.personalDetails.form.generic.error`
                )
              : ""
          }
        >
          {viewonly ? (
            !!values.birth_country?.length ? (
              <StepViewOnlyValue>
                {countries.find((c) => c.value === values.birth_country)
                  ?.label ?? ""}
              </StepViewOnlyValue>
            ) : null
          ) : (
            <Select
              size={inputSize}
              disabled={readonly}
              value={values.birth_country ?? undefined}
              options={countries}
              showSearch={true}
              placeholder={t(
                `${translationUserRole}Profile.personalDetails.form.birthCountry.placeholder`
              )}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(val) => {
                setValues({
                  ...values,
                  birth_country: val
                });
              }}
            />
          )}
        </Form.Item>
        <Form.Item
          label={t(
            `${translationUserRole}Profile.personalDetails.form.countryCode.title`
          )}
          className={
            viewonly && !values.phone_code?.length
              ? "noViewonlyValue"
              : "isValue"
          }
          validateStatus={
            errors?.phone_code && !values.phone_code ? "error" : ""
          }
          help={
            errors?.phone_code && !values.phone_code
              ? t(
                  `${translationUserRole}Profile.personalDetails.form.generic.error`
                )
              : ""
          }
        >
          {viewonly ? (
            !!values.phone_code?.length ? (
              <StepViewOnlyValue>
                {phoneCodes.find((c) => c.value === values.phone_code)?.label ??
                  ""}
              </StepViewOnlyValue>
            ) : null
          ) : (
            <CountryCodeSelect
              size={inputSize}
              disabled={readonly}
              value={values.phone_code}
              options={phoneCodes}
              showSearch={true}
              placeholder={t(
                `${translationUserRole}Profile.personalDetails.form.countryCode.placeholder`
              )}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(val) => {
                setValues({
                  ...values,
                  phone_code: val as string | null | undefined
                });
              }}
            />
          )}
        </Form.Item>
        <Form.Item
          label={t(
            `${translationUserRole}Profile.personalDetails.form.phone.title`
          )}
          className={
            viewonly && !values.phone_number?.length
              ? "noViewonlyValue"
              : "isValue"
          }
          validateStatus={
            errors?.phone_number && !values.phone_number ? "error" : ""
          }
          help={
            errors?.phone_number && !values.phone_number
              ? t(
                  `${translationUserRole}Profile.personalDetails.form.generic.error`
                )
              : ""
          }
        >
          {viewonly ? (
            !!values.phone_number?.length ? (
              <StepViewOnlyValue>{values.phone_number}</StepViewOnlyValue>
            ) : null
          ) : (
            <Input
              size={inputSize}
              disabled={readonly}
              value={values.phone_number ?? undefined}
              placeholder={t(
                `${translationUserRole}Profile.personalDetails.form.phone.placeholder`
              )}
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  /^[\d- ()]+$/.test(e.target.value)
                ) {
                  setValues({
                    ...values,
                    phone_number: e.target.value
                  });
                }
              }}
            />
          )}
        </Form.Item>
      </Root>
    );
  });
