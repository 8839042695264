import styled, { css } from "styled-components/macro";
import { DefaultFont, HeaderFont } from "../../../styles/fonts";
import { Progress as ProgressOriginal } from "antd";
import { EditableStepWrapper } from "../components";

export const StepContainerWide = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 64px 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  height: 100%;
`;

export const StepContainerDefault = styled.div<{ $showBorder?: boolean }>`
  width: 100%;
  max-width: 1000px;
  padding: 64px 24px 20px;
  box-sizing: border-box;

  ${(p) =>
    p.$showBorder
      ? css`
          padding: 24px;
          border: 1px solid #dcd6ce;
          border-radius: 4px;
        `
      : css``};
`;

export const StepContainerNoBackground = styled.div<{ $showBorder?: boolean }>`
  width: 100%;
  max-width: 1000px;
  padding: 64px 24px 20px;
  box-sizing: border-box;

  ${EditableStepWrapper} {
    background: none;
    padding: 0;
  }
`;

export const StepTitle = styled.h1<{ $mb?: string; $padding?: string }>`
  font-size: ${({ theme }) => theme.font.sizeTitle};
  font-weight: 500;
  font-family: ${({ theme }) => theme.text.family};
  line-height: ${({ theme }) => theme.text.defaultLineHeight};
  color: ${({ theme }) => theme.colors.hardGray};
  margin-bottom: ${({ $mb }) => $mb || "0.5rem"};
  padding: ${({ $padding }) => $padding || 0};
`;

export const StepDescription = styled.h2<{
  $fw?: "weightNormal" | "weightSemiMedium";
  $padding?: string;
}>`
  font-family: ${({ theme }) => theme.text.family};
  line-height: ${({ theme }) => theme.text.defaultLineHeight};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.midGrey};
  margin-bottom: 40px;
  font-weight: ${({ theme, $fw }) =>
    $fw ? theme.font[$fw] : theme.font.weightNormal};
  padding: ${({ $padding }) => $padding || 0};

  @media ${({ theme }) => theme.media.tablet} {
    font-size: ${({ theme }) => theme.font.size};
  }

  @media ${({ theme }) => theme.media.phone} {
    font-size: ${({ theme }) => theme.font.size};
  }
`;

export const Root = styled.div`
  height: calc(var(--app-height));
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  z-index: 2;
`;
export const HeaderButtons = styled.div`
  display: flex;
  gap: 16px;
  flex-grow: 1;
  justify-content: flex-end;
  z-index: 1;
`;
export const StepContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;

  .ant-form-item-row {
    .ant-form-item-label {
      label {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
`;

export const Footer = styled.div<{ $gap?: string; $isResponsive?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top: 1px solid rgba(128, 128, 128, 0.63);
  background-color: #ffffff;
  gap: ${({ $gap }) => $gap || 0};

  ${({ $isResponsive }) =>
    $isResponsive &&
    css`
      flex-wrap: wrap;
    `};
`;
export const DebugInfo = styled.div`
  color: gray;
  font-size: 14px;
  font-family: monospace;
`;
export const LoadingOverlay = styled.div`
  width: 100%;
  height: calc(var(--app-height));
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;
export const LoadingOverlayText = styled.div`
  ${HeaderFont};
`;
export const Progress = styled(ProgressOriginal)`
  margin-bottom: 0 !important;
  height: 8px;
  position: relative;
  z-index: 2;

  .ant-progress-inner {
    top: -10px;
  }
`;

export const StepViewOnlyValue = styled.p`
  color: ${({ theme }) => theme.colors.hardGray};
`;

export const TextButton = styled.button<{ $color?: string }>`
  ${DefaultFont};
  color: #c35113;

  font-size: 16px !important;
  border: none;
  background: none;
  cursor: pointer;
`;

export const AlreadyRegisteredContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`;
