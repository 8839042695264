import { ReactNode, lazy } from "react";
import styled from "styled-components/macro";
import {
  BECOME_HOST_ROUTE,
  BLOG_ROUTE,
  VACANCIES_ROUTE,
  FAQ_ROUTE,
  CONTACT_ROUTE,
  FIND_ROOM_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  HOME_ROUTE,
  STUDENT_PROFILE_VIEW_ROUTE,
  STUDENT_PROFILE_EDIT_FIRST_ROUTE,
  ROOM_INFO_ROUTE,
  STUDENT_MATCHES_ROUTE,
  HOST_PROFILE_VIEW_ROUTE,
  HOST_PROFILE_EDIT_FIRST_ROUTE,
  HOST_ROOMS_ROUTE,
  HOST_TENANTS_ROUTE,
  STUDENT_PROFILE_EDIT_ROUTE,
  HOST_PROFILE_EDIT_ROUTE,
  CHANGE_PASSWORD,
  PRIVACY_ROUTE,
  COOKIES_ROUTE,
  CONTACT_DETAILS_ROUTE,
  TEAM_ROUTE,
  PRESS_ROUTE,
  MISSION_ROUTE,
  PARTNERS_ROUTE,
  STUDENT_ROOMS_ROUTE,
  HOST_CHATS_ROUTE_ID,
  STUDENT_CHATS_ROUTE_ID,
  STUDENT_WIZARD_ROUTE,
  HOST_WIZARD_ROUTE,
  HOST_HOUSE_WIZARD_ROUTE,
  HOST_HOUSE_EDIT_ROUTE,
  HOST_ROOM_REPUBLISH_ROUTE,
  HOST_PLAN_INTAKE_ROUTE,
  STUDENT_ROUTE_ID,
  RESET_PASSWORD_ROUTE,
  CONTRACT_WIZARD_ROUTE,
  UNSUBSCRIBE_EMAIL_ROUTE,
  CITIES_ROUTE,
  HOUSING_CORPORATIONS_ROUTE,
  STUDENT_SEARCHES_ROUTE
} from "./utils/constants";
import { HomePage } from "./landing-components/HomePage";
import { People } from "./components/Icons/People";
import { SingleUser } from "./components/Icons/SingleUser";
import { BuildingIcon } from "./components/Icons/Building";
import { KeyIcon } from "./components/Icons/Key";
import { ThumbUpIcon } from "./components/Icons/ThumbUp";
import { WizardHostPage } from "./pages/Wizard/WizardHostPage";
import { BookmarkIcon } from "./components/Icons/Bookmark";

// Public lazily loaded pages components
const LoginLazy = lazy(() => import("./pages/Auth/Login"));
const RegisterLazy = lazy(() => import("./pages/Auth/Register"));
const ResetPasswordLazy = lazy(() => import("./pages/Auth/ResetPassword"));
const UnsubscribeEmailLazy = lazy(
  () => import("./pages/Auth/UnsubscribeEmail")
);
const UserMainPageLazy = lazy(() => import("./pages/UserMainPage"));
const ContactLazy = lazy(() => import("./pages/rootpage/Contact"));
const ContactDetailsLazy = lazy(
  () => import("./pages/rootpage/ContactDetails")
);
const CookiesLazy = lazy(() => import("./pages/rootpage/Cookies"));
const BlogLazy = lazy(() => import("./pages/rootpage/Blog"));
const PostBlogLazy = lazy(() => import("./pages/rootpage/Blog/CardBlog"));
const FAQLazy = lazy(() => import("./pages/rootpage/Faq"));
const PrivacyLazy = lazy(() => import("./pages/rootpage/Privacy"));
const PressLazy = lazy(() => import("./pages/rootpage/Press"));
const MissionLazy = lazy(() => import("./pages/rootpage/Mission"));
const TeamLazy = lazy(() => import("./pages/rootpage/Team"));
const VacanciesLazy = lazy(() => import("./pages/rootpage/Vacancies"));
const PostVacanciesLazy = lazy(
  () => import("./pages/rootpage/Vacancies/CardVacancies")
);
const PartnersLazy = lazy(() => import("./pages/rootpage/Partners"));
const CitiesLazy = lazy(() => import("./pages/rootpage/Cities"));
const HousingCorporationsLazy = lazy(
  () => import("./pages/rootpage/Corporations")
);

// Common lazily loaded pages components
const DetailedRoomInfoLazy = lazy(() => import("./pages/DetailedRoomInfo")); // and may be public
const ChangePasswordLazy = lazy(() => import("./pages/Auth/ChangePassword"));
const ChatPageLazy = lazy(() => import("./pages/ChatPage"));

// Student lazily loaded pages components
const WizardStudentPageLazy = lazy(
  () => import("./pages/Wizard/WizardStudentPage")
);
const StudentProfileLazy = lazy(
  () => import("./pages/ProfileUser/StudentProfile")
);
const StudentProfileEditLazy = lazy(
  () => import("./pages/ProfileUser/StudentProfileEdit")
);
const StudentProfileCancelableLazy = lazy(
  () => import("./pages/ProfileUser/StudentProfileCancelable")
);
const StudentMatchesLazy = lazy(() => import("./pages/StudentMatches"));
const StudentSearchesLazy = lazy(() => import("./pages/StudentSearches"));

// Host lazily loaded pages components
const HouseWizardLazy = lazy(() => import("./components/Wizard/House"));
const HostProfileLazy = lazy(() => import("./pages/ProfileUser/HostProfile"));
const HostProfileEditLazy = lazy(
  () => import("./pages/ProfileUser/HostProfileEdit")
);
const HostProfileCancelableLazy = lazy(
  () => import("./pages/ProfileUser/HostProfileCancelable")
);
const PlanIntakePageLazy = lazy(
  () => import("./pages/ProfileUser/PlanIntakePage")
);
const HostRoomsLazy = lazy(() => import("./pages/HostRooms"));
const HouseEditPageLazy = lazy(() => import("./pages/HostRooms/HouseEditPage"));
const HouseRepublishPageLazy = lazy(
  () => import("./pages/HostRooms/HouseRepublishPage")
);
const MyTenantsLazy = lazy(() => import("./pages/MyTenants"));
const StudentOverviewLazy = lazy(() => import("./pages/StudentOverview"));
const WizardContractPageLazy = lazy(
  () => import("./pages/Wizard/WizardContractPage")
);

export const RouteIcons = {
  Profile: styled(SingleUser)`
    path {
      stroke: ${(p) => p.theme.colors.textGray};
    }
  `,
  Rooms: styled(BuildingIcon)`
    path {
      stroke: ${(p) => p.theme.colors.textGray};
    }
  `,
  Matching: styled(People)`
    path {
      stroke: ${(p) => p.theme.colors.textGray};
    }
  `,
  Tenants: styled(KeyIcon)`
    path {
      stroke: ${(p) => p.theme.colors.textGray};
    }
  `,
  TipsAndTricks: styled(ThumbUpIcon)`
    path {
      stroke: ${(p) => p.theme.colors.textGray};
    }
  `
};

export interface IRoute {
  readonly path: string;
  keyLocalize: string;
  component?: any; // undefined for SearchRoomsMap only
  icon?: ReactNode;
}

export type TMenuRoute = Omit<IRoute, "component">;

export const publicRoutes: IRoute[] = [
  {
    path: FIND_ROOM_ROUTE,
    component: <UserMainPageLazy />,
    keyLocalize: "findroom"
  },
  {
    path: BECOME_HOST_ROUTE,
    component: <UserMainPageLazy />,
    keyLocalize: "becomehost"
  },
  {
    path: CONTACT_ROUTE,
    component: <ContactLazy />,
    keyLocalize: "contact"
  },
  {
    path: FAQ_ROUTE,
    component: <FAQLazy />,
    keyLocalize: "faq"
  },
  {
    path: MISSION_ROUTE,
    component: <MissionLazy />,
    keyLocalize: "mission"
  },
  {
    path: BLOG_ROUTE,
    component: <BlogLazy />,
    keyLocalize: "blog"
  }
];

export const publicRoutesHospi: IRoute[] = [
  { path: LOGIN_ROUTE, component: <LoginLazy />, keyLocalize: "login" },
  {
    path: REGISTER_ROUTE,
    component: <RegisterLazy />,
    keyLocalize: "register"
  },
  {
    path: RESET_PASSWORD_ROUTE,
    component: <ResetPasswordLazy />,
    keyLocalize: "login"
  },
  {
    path: UNSUBSCRIBE_EMAIL_ROUTE,
    component: <UnsubscribeEmailLazy />,
    keyLocalize: "login"
  },
  {
    path: HOME_ROUTE,
    component: <HomePage />,
    keyLocalize: "register"
  },
  {
    path: BLOG_ROUTE + "/:id/:slug",
    component: <PostBlogLazy />,
    keyLocalize: "blog"
  },
  {
    path: BLOG_ROUTE + "/:id",
    component: <PostBlogLazy />,
    keyLocalize: "blog"
  },
  {
    path: TEAM_ROUTE,
    component: <TeamLazy />,
    keyLocalize: "team"
  },
  {
    path: PARTNERS_ROUTE,
    component: <PartnersLazy />,
    keyLocalize: "partners"
  },
  {
    path: CITIES_ROUTE,
    component: <CitiesLazy />,
    keyLocalize: "cities"
  },
  {
    path: HOUSING_CORPORATIONS_ROUTE,
    component: <HousingCorporationsLazy />,
    keyLocalize: "corporations"
  },
  {
    path: PRESS_ROUTE,
    component: <PressLazy />,
    keyLocalize: "press"
  },
  {
    path: MISSION_ROUTE,
    component: <MissionLazy />,
    keyLocalize: "mission"
  },
  {
    path: VACANCIES_ROUTE,
    component: <VacanciesLazy />,
    keyLocalize: "vacancies"
  },
  {
    path: VACANCIES_ROUTE + "/:id",
    component: <PostVacanciesLazy />,
    keyLocalize: "vacancies"
  },
  {
    path: PRIVACY_ROUTE,
    component: <PrivacyLazy />,
    keyLocalize: "privacy"
  },
  {
    path: COOKIES_ROUTE,
    component: <CookiesLazy />,
    keyLocalize: "cookies"
  },
  {
    path: CONTACT_DETAILS_ROUTE,
    component: <ContactDetailsLazy />,
    keyLocalize: "contactdetails"
  },
  {
    path: ROOM_INFO_ROUTE + "/:id",
    component: <DetailedRoomInfoLazy />,
    keyLocalize: "",
    icon: <RouteIcons.Rooms />
  }
];

export const studentRoutes: IRoute[] = [
  {
    path: STUDENT_WIZARD_ROUTE,
    component: <WizardStudentPageLazy />,
    keyLocalize: "wizard",
    icon: null
  },
  // {
  //   path: STUDENT_WIZARD_FINISHED_ROUTE,
  //   component: <WizardStepStartSearchPageLazy />,
  //   keyLocalize: "wizardSearch",
  //   icon: null
  // },
  {
    path: STUDENT_PROFILE_VIEW_ROUTE,
    component: <StudentProfileLazy />,
    keyLocalize: "profile",
    icon: <RouteIcons.Profile />
  },
  {
    path: STUDENT_PROFILE_EDIT_FIRST_ROUTE,
    component: <StudentProfileEditLazy />,
    keyLocalize: "profile",
    icon: <RouteIcons.Profile />
  },
  {
    path: STUDENT_PROFILE_EDIT_ROUTE,
    component: <StudentProfileCancelableLazy />,
    keyLocalize: "profile",
    icon: <RouteIcons.Profile />
  },
  {
    path: STUDENT_ROOMS_ROUTE,
    // component: <SearchRoomsMap />,
    keyLocalize: "rooms",
    icon: <RouteIcons.Rooms />
  },
  {
    path: STUDENT_SEARCHES_ROUTE,
    component: <StudentSearchesLazy />,
    keyLocalize: "saved",
    icon: <BookmarkIcon className="savedNavbarIcon" />
  },
  {
    path: ROOM_INFO_ROUTE + "/:id",
    component: <DetailedRoomInfoLazy />,
    keyLocalize: "rooms",
    icon: <RouteIcons.Rooms />
  },
  {
    path: STUDENT_CHATS_ROUTE_ID,
    component: <ChatPageLazy />,
    keyLocalize: "chats",
    icon: <RouteIcons.Matching />
  },
  {
    path: STUDENT_MATCHES_ROUTE,
    component: <StudentMatchesLazy />,
    keyLocalize: "stay",
    icon: <RouteIcons.Tenants />
  },
  {
    path: CONTRACT_WIZARD_ROUTE,
    component: <WizardContractPageLazy />,
    keyLocalize: "",
    icon: null
  },
  {
    path: CHANGE_PASSWORD,
    component: <ChangePasswordLazy />,
    keyLocalize: "login"
  }
];

export const hostRoutes: IRoute[] = [
  {
    path: HOST_PROFILE_VIEW_ROUTE,
    component: <HostProfileLazy />,
    keyLocalize: "profile",
    icon: <RouteIcons.Profile />
  },
  {
    path: HOST_PROFILE_EDIT_FIRST_ROUTE,
    component: <HostProfileEditLazy />,
    keyLocalize: "profile",
    icon: <RouteIcons.Profile />
  },
  {
    path: HOST_PROFILE_EDIT_ROUTE,
    component: <HostProfileCancelableLazy />,
    keyLocalize: "profile",
    icon: <RouteIcons.Profile />
  },
  {
    path: HOST_ROOMS_ROUTE,
    component: <HostRoomsLazy />,
    keyLocalize: "advertisements",
    icon: <RouteIcons.Rooms />
  },
  {
    path: ROOM_INFO_ROUTE + "/:id",
    component: <DetailedRoomInfoLazy />,
    keyLocalize: "rooms",
    icon: <RouteIcons.Rooms />
  },
  {
    path: HOST_CHATS_ROUTE_ID,
    component: <ChatPageLazy />,
    keyLocalize: "chats",
    icon: <RouteIcons.Matching />
  },
  {
    path: HOST_TENANTS_ROUTE,
    component: <MyTenantsLazy />,
    keyLocalize: "tenants",
    icon: <RouteIcons.Tenants />
  },
  {
    path: STUDENT_ROUTE_ID,
    component: <StudentOverviewLazy />,
    keyLocalize: "",
    icon: null
  },
  {
    path: CHANGE_PASSWORD,
    component: <ChangePasswordLazy />,
    keyLocalize: "login"
  },
  {
    path: HOST_WIZARD_ROUTE,
    component: <WizardHostPage />,
    keyLocalize: "wizard",
    icon: null
  },
  {
    path: HOST_HOUSE_WIZARD_ROUTE,
    component: <HouseWizardLazy />,
    keyLocalize: "houseWizard",
    icon: null
  },
  {
    path: CONTRACT_WIZARD_ROUTE,
    component: <WizardContractPageLazy />,
    keyLocalize: "",
    icon: null
  },
  {
    path: HOST_HOUSE_EDIT_ROUTE,
    component: <HouseEditPageLazy />,
    keyLocalize: "houseWizard",
    icon: null
  },
  {
    path: HOST_ROOM_REPUBLISH_ROUTE,
    component: <HouseRepublishPageLazy />,
    keyLocalize: "",
    icon: null
  },
  {
    path: HOST_PLAN_INTAKE_ROUTE,
    component: <PlanIntakePageLazy />,
    keyLocalize: "",
    icon: null
  }
];
