import {
  ReactNode,
  RefAttributes,
  createContext,
  useContext,
  FC,
  useMemo,
  useState,
  lazy
} from "react";
import { DialogWrapper } from "components/containers";
import {
  DangerButton,
  PopupTitleH2,
  PrimaryButton,
  TextPlain
} from "components/generic";
import { ButtonProps } from "antd/es/button/button";

const ModalLazy = lazy(() => import("../components/Modal"));

interface IModalOptions {
  title: string;
  content: string | ReactNode;
  confirmButtonText: string;
  confirmButtonProps?: ButtonProps & RefAttributes<HTMLButtonElement>;
  cancelButtonText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ContextConfirmModal = createContext<{
  show: (options: IModalOptions) => void;
  hide: () => void;
}>({
  show: () => {},
  hide: () => {}
});

export const useConfirmModal = () => {
  return useContext(ContextConfirmModal);
};

export const ConfirmModalProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const [modalState, setModalState] = useState<IModalOptions | null>(null);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const modalApi = useMemo(() => {
    return {
      show: (options: IModalOptions) => setModalState(options),
      hide: () => setModalState(null)
    };
  }, [setModalState]);

  return (
    <ContextConfirmModal.Provider value={modalApi}>
      {children}
      {modalState && (
        <ModalLazy
          isShow={true}
          handleClose={async () => {
            await modalState?.onCancel?.();
            modalApi.hide();
          }}
        >
          <DialogWrapper style={{ width: "90%", maxWidth: "600px" }}>
            <PopupTitleH2>{modalState.title}</PopupTitleH2>
            <TextPlain style={{ wordBreak: "break-word" }} color="hardGray">
              {Array.isArray(modalState.content)
                ? modalState.content.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))
                : modalState.content}
            </TextPlain>
            <div className="buttons">
              {modalState.onCancel || modalState.cancelButtonText ? (
                <DangerButton
                  loading={isLoadingCancel}
                  size="large"
                  danger
                  onClick={async () => {
                    try {
                      setIsLoadingCancel(true);
                      await modalState?.onCancel?.();
                      modalApi.hide();
                    } finally {
                      setIsLoadingCancel(false);
                    }
                  }}
                  className="butCancel"
                >
                  {modalState.cancelButtonText}
                </DangerButton>
              ) : (
                <span />
              )}
              <PrimaryButton
                type="primary"
                size="large"
                loading={isLoadingConfirm}
                onClick={async () => {
                  try {
                    setIsLoadingConfirm(true);
                    await modalState.onConfirm();
                    modalApi.hide();
                  } finally {
                    setIsLoadingConfirm(false);
                  }
                }}
                {...modalState.confirmButtonProps}
              >
                {modalState.confirmButtonText}
              </PrimaryButton>
            </div>
          </DialogWrapper>
        </ModalLazy>
      )}
    </ContextConfirmModal.Provider>
  );
};
