import {
  IWizardStudentState,
  TWizardStepComponentEditable,
  WIZARD_STUDENT_STEPS_PATHS
} from "../../../interfaces";
import { FC, forwardRef, useImperativeHandle, memo, useState } from "react";
import { AvatarUploader } from "../../../../AvatarUploader";
import { UserAPI } from "../../../../../API/userApi";
import { getStepErrorsFromResponse } from "../../../utils";
import { RcFile } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";
import { useContextHospi } from "../../../../../context/ContextHospi";
import { FILE_UPLOAD_IMAGES_ALLOWED_MB_SIZE } from "../../../../../config/common";
import {
  compressUploadedImageFile,
  getImageUploadFileSize
} from "utils/helpers";

const statePath = WIZARD_STUDENT_STEPS_PATHS.Avatar;
export const WizardStepAvatar: TWizardStepComponentEditable<IWizardStudentState> =
  forwardRef(({ state, readonly, viewonly }, ref) => {
    const [errors, setErrors] = useState<{ user_image: string } | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { t } = useTranslation("wizard");
    const { cognitoUser } = useContextHospi();

    useImperativeHandle(ref, () => ({
      save: async () => {
        setErrors(null);
        if (!selectedFile && state[statePath]?.avatar_thumbnail_url) {
          return Promise.resolve(state);
        }

        let fileToSend = selectedFile;
        if (
          selectedFile &&
          selectedFile.size >
            getImageUploadFileSize(FILE_UPLOAD_IMAGES_ALLOWED_MB_SIZE)
        ) {
          try {
            fileToSend = await compressUploadedImageFile(selectedFile, true);
          } catch (error) {
            setErrors({
              user_image: t("step.Avatar.uploadError")
            });
            return Promise.reject(error);
          }
        }

        return await UserAPI.wizardStudentSaveAvatar({
          user_image: fileToSend as RcFile
        })
          .then((result) => {
            window.dataLayer.push({
              event: "student_wizard_2_avatar",
              user_id: cognitoUser?.id,
              user_type: "student"
            });

            return {
              ...state,
              [statePath]: result
            };
          })
          .catch((e) => {
            const errors = getStepErrorsFromResponse(e) as {
              user_image: string;
            } | null;
            if (errors) {
              setErrors(errors);
            } else {
              setErrors({
                user_image:
                  e?.response?.status === 413
                    ? t("step.Avatar.toBig")
                    : t("step.Avatar.uploadError")
              });
            }

            return Promise.reject(e);
          });
      }
    }));

    return (
      <WizardStepAvatarView
        avatarThumbnailUrl={state[statePath]?.avatar_thumbnail_url}
        errors={errors}
        onFileChange={(file) => {
          setSelectedFile(file);
        }}
        readonly={readonly}
        viewonly={viewonly}
      />
    );
  });

export const WizardStepAvatarView: FC<{
  avatarThumbnailUrl?: string | null;
  onFileChange: (file: File | null) => void;
  errors: { user_image?: string } | null;
  readonly?: boolean;
  viewonly?: boolean;
}> = memo(
  ({ avatarThumbnailUrl, onFileChange, readonly, viewonly, errors }) => {
    const { t } = useTranslation("student");
    return (
      <div>
        <AvatarUploader
          initialImageUrl={
            avatarThumbnailUrl
              ? `${avatarThumbnailUrl}?rand=${Math.random()}`
              : undefined
          }
          onFileSelect={(file) => {
            onFileChange(file ?? null);
          }}
          readonly={readonly || viewonly}
        />
        {errors?.user_image && (
          <div style={{ color: "red" }}>{t("wizard.steps.Avatar.error")}</div>
        )}
      </div>
    );
  }
);
